html{overflow-x:hidden !important;}
body{overflow-x:hidden !important;}

::-webkit-scrollbar{
    width:7px;
}

::-webkit-scrollbar-track{
    background-color: white;
}
::-webkit-scrollbar-thumb{
    background: black;
}



#logo{
    width:80px !important;
}

#hero_img{
    height: 689px;
    background-size: 100%;
    background-repeat: no-repeat !important;
}
#contact_header_img{
    background-size:cover;
    background-position: 50% 50%;
    background-repeat: no-repeat !important;
}
#about_header_img{
    height: 200px;
    background-size:cover;
    background-position: 50% 50%;
    background-repeat: no-repeat !important;
}
#kitchen_img{
    height: 428px;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat !important;
}
.gallery_img{
    height:500px;
    outline: 1px solid black;
}
#tile_bath_img{
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat !important;
}
#paint_roller_img{
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat !important;
}
#wood_stack_img{
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat !important;
}
#xp_img{
    width:100vw;
    height: 800px;
    background-size:100%;
    background-repeat: cover !important;
}

#title{
    font-family: 'Playfair Display', sans-serif;
    font-size: 90px;
    font-weight:400;
}
#subtitle{
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    font-size:32px;
    font-weight: 400;
}
#header1, #xp_header{
    font-family: 'Playfair Display', sans-serif;
}
#cta_btn{
    font-family: 'Playfair Display', sans-serif;
}

#contact_page_header{
    font-family: 'Playfair Display', sans-serif;
}

#side_bar_link{
    color:inherit !important;
    text-decoration: none !important;
}

.navoption{
    font-size: 26px;
    font-weight: 200;
}
.light_txt{
    font-weight: 200;
}
.superlight_txt{
    font-weight: 100;
}
.pfd{
    font-family: 'Playfair Display', sans-serif;
}
.hvn{
    font-family: 'Helvetica Neue', sans-serif;
}

*{
    font: 'Helvetica Neue';

}
li{
    list-style: circle;
}

h3{padding-top: 20px;}

h2{
    font-weight: bold !important;
    font-size:20px !important;
}


@media only screen and (max-width: 1024px){
    #hero_img{
        width:100vw;
        height:600px;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat !important;
    }
    #kitchen_img{
        height: 420px;
    }
}

@media only screen and (max-width: 768px){
    #title{
        font-size: 60px;
    }
    #subtitle{
        font-size:32px;
    }
}

@media only screen and (max-width:639px){
    .gallery_img{
        height:auto !important;
        outline: 1px solid black;
    }
}

@media only screen and (max-width: 470px){
    #title{
        font-size: 52px;
    }
    #subtitle{
        font-size:24px;
    }
}
@media only screen and (max-width: 410px){
    #title{
        font-size: 46px;
    }
    #subtitle{
        font-size:22px;
    }
}
@media only screen and (max-width: 470px){
    #title{
        font-size: 38px;
    }
    #subtitle{
        font-size:20px;
    }
}
